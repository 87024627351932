import Swal from 'sweetalert2'

export const is_mobile = () => {
    let mobile_device = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
    const ua = navigator.userAgent.toLowerCase();
    for (let i = 0; i < mobile_device.length; i++) {
        let cur_mb = mobile_device[i].toLowerCase();
        if (ua.match(cur_mb)) {
            return true;
        }
    }

    return false;
}

export const b_left = (service) => {
    // D: WFSS; W: CNC; T: TC
    const s = service.toUpperCase();
    switch (s) {
        case 'D':
            window.open("https://parent.hinet.net/bind_categoryBlock/create_pmt_disp.jsp");
            break;
        case 'W':
            window.history.go(-1);
            break;
        case 'T':
            window.open("https://hitc.hinet.net/newlogin/login_frame_30.jsp");
            break;
        default:
            window.open("https://wfs.hinet.net/");
            break;
    }
}

export const b_right = (service, hex_string = "", host = "") => {
    // D: WFSS; W: CNC; T: TC
    const s = service.toUpperCase();
    switch (s) {
        case 'D':
            window.open("https://familycare.hinet.net/report");
            break;
        case 'W':
            if (hex_string.length > 0 && host.length > 0) {
                warning(host, hex_string);
            }
            break;
        case 'T':
            window.open("https://hitc.hinet.net/newtm/read_time_frame.jsp");
            break;
        default:
            window.open("https://wfs.hinet.net/");
            break;
    }
}

export const redirect_to_chts = () => {
    window.location.href = "https://wfs.hinet.net/";
}

export const encode_base16 = (origin_string) => {
    // 將字串以 base16 進行編碼
    const encoder = new TextEncoder();
    const bytes = encoder.encode(origin_string);
    let result = '';

    bytes.forEach((byte) => (
        result += byte.toString(16).padStart(2, '0')
    ))

    return result;
};

export const decode_base16 = (hex_string) => {
    // 將 base16 字串進行解碼
    const bytes = new Uint8Array(hex_string.length / 2);
    for (let i = 0, j = 0; i < hex_string.length; i += 2, j++) {
        bytes[j] = parseInt(hex_string.substr(i, 2), 16);
    }

    const decoder = new TextDecoder();
    return decoder.decode(bytes);
}

export const warning = (host, hex_string) => {
    // Alert CNC Warning
    let origin_str = decode_base16(hex_string);
    let url = 'http://' + host + origin_str;
    
    Swal.fire({
        title: "確定前往該網址?",
        text: `以下網址有受駭風險，確定要前往嗎?\n${url}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "是，我確定",
        confirmButtonColor: "#ff4d4f",
        cancelButtonText: "取消",
        cancelButtonColor: "#21DB54",
        focusConfirm: false,
        focusCancel: true
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = `${url}?reject=yes`;
        }
    })
};