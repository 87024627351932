import './App.css';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CHT from './img/cht_logo.svg';
import Logo_CNC from './img/cnc_logo.svg';
import Logo_WFSS from './img/wfs_logo.svg';
import Logo_TC from './img/tc_logo.svg';
import Block_WFSS from './img/block_wfs.png';
import Block_CNC from './img/block_cnc.png';
import Block_TC from './img/block_tc.png';
import { b_left, b_right } from './others';

const setting = {
	"D": {
		"title": "色情守門員攔阻畫面",
		"logo_src": Logo_WFSS,
		"block_logo": Block_WFSS,
		"service_protected": "色情守門員",
		"protect_info": "您所前往的網頁已受控管",
		"bl_text": "解除防護",
		"br_text": "誤擋申訴"
	},
	"W": {
		"title": "防駭守門員攔阻畫面",
		"logo_src": Logo_CNC,
		"block_logo": Block_CNC,
		"service_protected": "防駭守門員",
		"protect_info": "所前往的網頁可能為危險網頁，建議您不要繼續前往",
		"bl_text": "離開此網頁",
		"br_text": "仍要前往"
	},
	"T": {
		"title": "上網時間管理攔阻畫面",
		"logo_src": Logo_TC,
		"block_logo": Block_TC,
		"service_protected": "上網時間管理",
		"protect_info": "目前為限制上網時段，讓眼睛休息一下吧",
		"bl_text": "解除防護",
		"br_text": "查詢上網時間"
	},
	"": { // 處理沒有參數時
		"title": "色情守門員攔阻畫面",
		"logo_src": Logo_WFSS,
		"block_logo": Block_WFSS,
		"service_protected": "色情守門員",
		"protect_info": "您所前往的網頁已受控管",
		"bl_text": "解除防護",
		"br_text": "誤擋申訴"
	}
}

const App = () => {
    const [isRendered, setIsRendered] = useState(false);
	const [curService, setCurService] = useState("");
	const [title, setTitle] = useState("");
	const [hexStr, setHexStr] = useState(""); // CNC url
	const [host, setHost] = useState(""); // CNC host

	const location = useLocation();

    useEffect(() => {
        // 避免FOUC
        setIsRendered(true);
    }, [])

	useEffect(() => {
		let query_string = new URLSearchParams(location.search);
		let current_service = query_string.get("flag") ? query_string.get("flag").toUpperCase() : "";
		let cur_uri = query_string.get("uri") ? query_string.get("uri") : "";
		let cur_host = query_string.get("host") ? query_string.get("host") : "";

		if (current_service.length > 0) {
			setCurService(current_service);
			setTitle(setting[curService]['title']);
			document.title = title;

			if (current_service === "W" && cur_uri.length > 0 && cur_host.length > 0) {
				// CNC 跳轉處理
				setHexStr(cur_uri);
				setHost(cur_host);
			}
		} else {
			document.title = "攔阻畫面";
			// console.log("Not Allowed. redirect to CHT home page.");
		}
	}, [location, curService, title])

	return (
            isRendered ?
			<div className="content_blcok">
				<div className='logos'>
					<img src={CHT} alt={'CHT logo'} className={'l_logo'} width={153} height={50}/>
					<img src={setting[curService]['logo_src']} alt={`${curService.toUpperCase()} logo`} className={'r_logo'} width={215} height={75.44}/>
				</div>
				<div className='service_detail'>
					<div className='service_inner service_text'>
						<div>
							<img className='service_logo' alt={'service logo'} src={setting[curService]['block_logo']} />
						</div>
						<div className='text_box'>
							<p className='outer_text top'>您的網路已啟動</p>
							<p className='outer_text bottom'>「HiNet{setting[curService]['service_protected']}」防護</p>
							<p className='inner_text'>{setting[curService]['protect_info']}</p>			
						</div>
					</div>
					<div className='service_inner'>
						<button className={`default b_left ${curService}`} type={'button'} onClick={() => (b_left(curService))}>
							{setting[curService]['bl_text']}
						</button>
						<button className={`default b_right ${curService}`} type={'button'} onClick={() => (b_right(curService, hexStr, host))}>
							{setting[curService]['br_text']}
						</button>
					</div>
				</div>
			</div>
            :
            <div/>
	)
}

export default App;